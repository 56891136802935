import React from "react";
import { Link } from "react-router-dom";

import "./SideDrawerStyles.scss";

export const routes = [
  { path: "/aboutme", label: "About Oren" },
  { path: "/teaching", label: "Teaching" },
  { path: "/pertubando", label: "Pertubando" },
  { path: "/jazz_for_toddlers", label: "Jazz for Toddlers" },
  { path: "/breathwork", label: "Breathwork" },
  { path: "/listen", label: "Listen" },
  { path: "/gallery", label: "Gallery" },
];

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div style={{ padding: "15px" }} className='toolbar__toggle-button'>
          {/* <DrawerToggleButton click={props.click} /> */}
        </div>
        <div className='side-drawer__menu'>
          <Link className=' side-drawer__link ' onClick={props.click} to='/'>
            <h3>OREN MARSHALL</h3>
          </Link>
          {routes.map((r) => (
            <Link onClick={props.click} className='side-drawer__link' to={r.path}>
              {r.label}
            </Link>
          ))}
        </div>
      </nav>
    </div>
  );
};

export default sideDrawer;

import React, { useState, useEffect } from "react";
import GalleryDisplay from "../../components/Gallery/Gallery";
import { getGalleryImages } from "../../contentful";

const GalleryPage = () => {
  const [images, setImages] = useState([]);
  const [displayImages, setDisplayImages] = useState([]);

  useEffect(() => {
    getGalleryImages().then((data) => {
      setImages(data);
      setDisplayImages(data);
    });
  }, []);

  const filterImages = (value) => {
    if (value === "formal")
      setDisplayImages(images.filter((i) => i.fields.formal));
    if (value === "informal")
      setDisplayImages(images.filter((i) => !i.fields.formal));
    if (value === "all") setDisplayImages(images);
  };

  return (
    <div className='page-content'>
      {/* <div className='button-group'>
        <button
          className='button'
          onClick={() => {
            filterImages("formal");
          }}
        >
          Formal Images
        </button>
        <button
          className='button'
          onClick={() => {
            filterImages("informal");
          }}
        >
          Informal Images
        </button>
        <button
          className='button'
          onClick={() => {
            filterImages("all");
          }}
        >
          All Images
        </button>
      </div> */}
      <GalleryDisplay images={displayImages} />
    </div>
  );
};

export default GalleryPage;

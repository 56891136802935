import React from "react";

import "./DrawerToggleButton.scss";

const drawerToggleButton = (props) => (
  <button className='toggle-button' onClick={props.click}>
    <div className='toggle-button__line' />
    <div className='toggle-button__line' />
    <div className='toggle-button__line' />
  </button>
  // <button className='menu-text' onClick={props.click}>
  //   MENU
  // </button>
);

export default drawerToggleButton;

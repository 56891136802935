import React, { useState, useEffect } from "react";
import "./HeroStyles.scss";
import ScrollAnimation from "react-animate-on-scroll";

const Hero = ({ image }) => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div className='hero'>
        <img src={image.fields.file.url} alt='oren' />
      </div>
      <div className='hero__overlay' style={{ transform: `translateY(${offsetY * 0.2}px)` }}>
        <ScrollAnimation animateIn='bounceInDown' animateOut='fadeOut'>
          <h1>OREN MARSHALL</h1>
        </ScrollAnimation>
      </div>
    </>
  );
};

export default Hero;

import React, { useEffect, useState } from "react";
import "./styles.scss";
import { getTrioInfo } from "../../contentful";
import AudioPlayer from "../SingleAudioPlayer/SingleAudioPlayer";
import { Link } from "react-router-dom";

const TrioHero = () => {
  const [info, setInfo] = useState({});
  useEffect(() => {
    getTrioInfo().then((data) => {
      setInfo(data[0].fields);
      console.log(data[0].fields);
    });
  }, []);

  return (
    <div className='trio-hero'>
      <h2 className='trio-hero__title'>{info.title}</h2>
      {info.mainImage && (
        <div className='trio-hero__image'>
          <img src={info?.mainImage.fields.file.url} />
          <div className='trio-hero__audio-players'>
            {info.mp3s?.map((mp3) => (
              <AudioPlayer
                src={mp3.fields.file.url}
                details={mp3.fields.title}
              />
            ))}
          </div>
        </div>
      )}
      <div className='more-button__container'>
        <Link className='button-link more-button' to='/pertubando'>
          more about Pertubando >>>
        </Link>
      </div>
    </div>
  );
};

export default TrioHero;

import React, { useEffect } from "react";
import "./BreathworkCourseStyles.scss";

const BreathworkCourse = () => {
  const sections = [1, 2, 3, 4, 5, 6];

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className='course-container'>
      <div className='course__header'>
        <div className='course__header__box'>
          <h2>Breathwork</h2>
          <span>Section 1</span>
        </div>
        <div>
          <span>logged in as: Adam Woolf</span>
        </div>
      </div>
      <div className='course__content'>
        <aside className='course__sections-menu'>
          {sections.map((s) => (
            <div className='course__sections-menu__item'>
              <div>
                <h6>Section #</h6>
                <span className='course__sections-menu__item__desc'>
                  integrated breathing...
                </span>
              </div>
              <div>
                <iframe
                  src='https://www.youtube.com/embed/xFvV8il54bg?rel=0'
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          ))}
        </aside>
        <div className='course__text-container'>
          <div className='course__top'>
            <div className='course__video-container'>
              <iframe
                src='https://www.youtube.com/embed/xFvV8il54bg?rel=0'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowfullscreen
              ></iframe>
            </div>
            <div style={{ paddingLeft: 30 }}>
              Links, download buttons, summary goes here
            </div>
          </div>
          <div className='course__scroller'>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
            <div>
              {" "}
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel
              eveniet similique mollitia ex est beatae itaque repellendus
              eligendi eos. Quae, adipisci reiciendis. Tempore sunt mollitia
              fuga quod cumque unde qui.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreathworkCourse;

import React from 'react'

const SoloWork = () => {
    return (
        <div>
            <h3>Solo work</h3>
        </div>
    )
}

export default SoloWork

import React, { useContext, useState, useEffect } from "react";
import { ShopContext } from "../../context/ShopContext";
import ProductCard from "./ProductCard";
import { Row, Col } from "react-bootstrap";
import { getShopText } from "../../contentful";
import TitleBar from "../TitleBar";
import marked from "marked";
import { FaShoppingBasket } from "react-icons/fa";

const Shop = () => {
  const { products, fetchProducts, basket, openCart } = useContext(ShopContext);
  const [text, setText] = useState([]);
  const [video, setVideo] = useState([])

  useEffect(() => {
    fetchProducts();
    getShopText().then((data) => setText(data));
  }, []);

  let basketCount;
if(basket.lineItems){basketCount = <span>{basket.lineItems.length}</span>}
  return (
    <div className="content">
      <TitleBar title="The JS Modular Cornett" />
      <div style={{display:'flex', justifyContent:'space-between', cursor:'pointer'}} >
      <h4>Welcome to the shop</h4>
      <div onClick={openCart} >
        <FaShoppingBasket size={25}/>{basketCount}
      </div>
      </div>

      <Row>
        <Col md={4}>
          {products.slice(0, 1).map((item) => (
            <ProductCard key={item.id} item={item} />
          ))}
        </Col>
        <Col md={8}>
          {text.map((text) => {
            const content = marked(text.fields.content);

            return (
              <div
                style={{ textAlign: "justify", padding: 20 }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            );
          })}

          {video.slice(0,1).map(video =>  <video controls><source src={video.fields.video.fields.file.url} /></video>
)}
        </Col>
      </Row>

      <h5>Additional parts currently available to order separately</h5>
      <Row>
        {products &&
          products.slice(1, products.length).map((item) => (
            <Col md={3}>
              {" "}
              <ProductCard key={item.id} item={item} />
            </Col>
          ))}
      </Row>
      <h5>Mouthpieces</h5>
      {text.map((text) => {
        let content = marked(text.fields.mouthpieces);
        let comingSoon = marked(text.fields.comingSoon);
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div dangerouslySetInnerHTML={{ __html: comingSoon }} />
          </div>
        );
      })}
    </div>
  );
};

export default Shop;

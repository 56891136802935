import React, {useContext, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import {ShopContext} from '../../context/ShopContext'
import { FaShoppingCart, FaArrowLeft, FaShoppingBasket, FaShoppingBag } from 'react-icons/fa'
import TitleBar from '../TitleBar'

const SingleProductPage = () => {
    const {id} = useParams();

    const {product, fetchProductWithId, openCart, addItemToCart} = useContext(ShopContext)

    useEffect(() => {
        fetchProductWithId(id)
    }, [fetchProductWithId, id])

    if(!product){
    return <h3>Loading...</h3>
    }

    return (
      <div className="content">
   
        <TitleBar title={product.title}/>
       

      <Row>
        <Col md={4}>
          {product.images && (
            <img style={{ width: "100%" }} src={product.images[0].src} />
          )}
        </Col>
        <Col md={8}>
          <div className="single-product-description">
          <h5>{product.title}</h5>
          <p>{product.description}</p>
<div>
          <button className="btn btn-light" onClick={() => {
        addItemToCart( product.variants[0].id  ,1) 
        openCart()
      }} >add to basket <FaShoppingBasket/></button>
      </div>
      </div>
        </Col>
      </Row>
  
<div style={{marginTop:25}}>

<Link to="/shop" className="btn btn-dark"><FaArrowLeft/> Back to all products</Link>
      <button className="btn btn-dark" onClick={openCart}>
        <FaShoppingBasket />
      </button>
      </div>
    </div>
    )
}

export default SingleProductPage

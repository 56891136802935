import React, { useRef, useState, useEffect } from "react";
import "./styles.scss";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";

const AudioPlayer = ({
  src,
  details = "",
  small,
  activeTrack,
  setActiveTrack,
  excerpt,
}) => {
  const audioPlayer = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const playPauseClick = () => {
    if (setActiveTrack) {
      if (activeTrack !== src) {
        setActiveTrack(src);
      } else {
        setActiveTrack("");
      }
      return;
    }
    if (!isPlaying) {
      audioPlayer?.current?.play();
      setIsPlaying(true);
    } else {
      audioPlayer?.current?.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (setActiveTrack) {
      if (activeTrack === src) {
        audioPlayer?.current?.play();
        setIsPlaying(true);
      }
      if (activeTrack !== src) {
        audioPlayer?.current?.pause();
        setIsPlaying(false);
      }
    }
  }, [activeTrack, setActiveTrack, src, audioPlayer]);

  let fadeTimer;

  var aud_fade_out = function () {
    clearTimeout(fadeTimer);
    if (audioPlayer.current.volume > 0.005) {
      audioPlayer.current.volume -= 0.005;
      fadeTimer = setTimeout(aud_fade_out, 50);
    } else {
      audioPlayer.current.volume = 0;
      audioPlayer.current.pause();
      audioPlayer.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (isPlaying && excerpt) {
      setTimeout(() => aud_fade_out(), 25000);
    }
  }, [isPlaying, excerpt]);

  return (
    <div>
      <audio ref={audioPlayer} controls>
        <source src={src} />
      </audio>
      <div className='player-container'>
        <button
          className={!small ? `play-pause` : "play-pause play-pause--small"}
          onClick={playPauseClick}
        >
          {isPlaying ? <FaPauseCircle /> : <FaPlayCircle />}
        </button>
        <p>
          <small
            className={isPlaying ? "track-details--active" : "track-details"}
          >
            {details}
          </small>
        </p>
      </div>
    </div>
  );
};

export default AudioPlayer;

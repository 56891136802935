import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ShopContext } from "../../context/ShopContext";
import { Row, Col } from "react-bootstrap";
import { FaShoppingBasket } from "react-icons/fa";

const ProductCard = ({ item }) => {
  const { addItemToCart, openCart } = useContext(ShopContext);
  return (
    <div className='product-card '>
      <div className='main-box'>
        <div className='overlay'>
          <p>{item?.description}</p>
          <p>£{item?.variants[0].price}</p>
          <button
            className='btn btn-dark'
            onClick={() => {
              addItemToCart(item.variants[0].id, 1);
              openCart();
            }}
          >
            add to basket <FaShoppingBasket />
          </button>
        </div>

        <div className='product-card-text'>
          <hp>{item.title}</hp>
        </div>

        <img src={item?.images[0]?.src} style={{ width: "100%" }} />
        <p>£{item.variants[0].price}</p>
      </div>
      <div style={{ marginTop: 10, display: "flex", flexDirection: "column" }}>
        <Link className='btn btn-dark' to={`/shop/${item.id}`}>
          more info
        </Link>
        <button
          className='btn btn-light'
          onClick={() => {
            addItemToCart(item.variants[0].id, 1);
            openCart();
          }}
        >
          add to basket <FaShoppingBasket />
        </button>
      </div>
    </div>
  );
};

export default ProductCard;

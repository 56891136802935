import React, { useState, useEffect } from "react";
import { getTrioInfo } from "../../contentful";
import "./PertubandoStyles.scss";
import marked from "marked";
import AudioPlayer from "../../components/SingleAudioPlayer/SingleAudioPlayer";

const Pertubando = () => {
  const [info, setInfo] = useState({});
  useEffect(() => {
    getTrioInfo().then((data) => {
      setInfo(data[0].fields);
    });
  }, []);
  if (!Object.values(info)) return null;
  return (
    <div className='pertubando page-entrance'>
      <div className='pertubando__gallery'>
        {info.gallery &&
          info.gallery.length > 0 &&
          info.gallery.map((image) => (
            <div className='pertubando__image'>
              <img src={image.fields.file.url} />
            </div>
          ))}
      </div>
      <div className='pertubando__tracks'>
        {info.mp3s &&
          info.mp3s.map((mp3) => (
            <AudioPlayer src={mp3.fields.file.url} details={mp3.fields.title} />
          ))}
      </div>
      <div>
        <h1 className='pertubando__heading'>{info.title}</h1>
        {info.text && (
          <div dangerouslySetInnerHTML={{ __html: marked(info.text) }} />
        )}
      </div>
    </div>
  );
};

export default Pertubando;

import React, { useState, useContext } from "react";
import { ShopContext } from "./context/ShopContext";
import { Route, Switch, useLocation } from "react-router-dom";
import "./styles/style.scss";
import Home from "./pages/Home/Home";
import Shop from "./components/shop/Shop";
import Backdrop from "./components/Backdrop/Backdrop";
import SideDrawer from "./components/Sidedrawer/SideDrawer";
import Toolbar from "./components/Toolbar/Toolbar";
import Biog from "./components/Biog";
import Footer from "./components/Footer";
import SingleProductPage from "./components/shop/SingleProductPage";
import Cart from "./components/shop/Cart";
import ContactBar from "./components/ContactBar/ContactBar";
import SoloWork from "./components/SoloWork";
import JazzForToddlers from "./pages/JazzforToddlers/JazzForToddlers";
import Links from "./components/Links";
import Blog from "./components/Blog";
import BlogSingle from "./components/BlogSingle";

import "animate.css/animate.compat.css";
import Teaching from "./components/Teaching/Teaching";
import AudioPlayer from "./components/AudioPlayer/AudioPlayer";
import GalleryPage from "./pages/GalleryPage/GalleryPage";
import AudioPage from "./pages/AudioPage/AudioPage";
import Pertubando from "./pages/Pertubando/Pertubando";
import Breathwork from "./pages/Breathwork/Breathwork";
import BreathworkCourse from "./pages/BreathworkCourse/BreathworkCourse";

function App() {
  const { cartOpen, closeCart } = useContext(ShopContext);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const backdropClickHandler = () => {
    setMenuOpen(false);
    closeCart();
  };

  const drawerToggleClickHandler = () => {
    if (menuOpen) setMenuOpen(false);
    if (!menuOpen) {
      setMenuOpen(true);
    }
  };

  let backdrop;
  if (menuOpen || cartOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }
  return (
    <div>
      <Toolbar menuOpen={menuOpen} drawerClickHandler={drawerToggleClickHandler} setShowModal={setShowModal} />
      <SideDrawer show={menuOpen} click={drawerToggleClickHandler} />
      <Cart show={cartOpen} />
      {backdrop}
      {location.pathname !== "/breathwork-course" && <AudioPlayer />}
      {/* <main style={{ marginTop: "76px" }}></main> */}
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/aboutme' component={Biog} />
        <Route path='/teaching' component={Teaching} />
        <Route path='/gallery' component={GalleryPage} />
        <Route path='/listen' component={AudioPage} />
        <Route path='/pertubando' component={Pertubando} />
        <Route path='/breathwork' component={Breathwork} />
        <Route path='/breathwork-course' component={BreathworkCourse} />

        <Route path='/solowork' exact component={SoloWork} />
        <Route path='/jazz_for_toddlers' exact component={JazzForToddlers} />
        <Route path='/links' exact component={Links} />
        <Route path='/blog/:id' exact component={BlogSingle} />
        <Route path='/blog' exact component={Blog} />
        <Route path='/shop/:id' component={SingleProductPage} />
        <Route path='/shop' component={Shop} />
      </Switch>
      <ContactBar showModal={showModal} setShowModal={setShowModal} />
      <Footer />
    </div>
  );
}

export default App;

import React, { useState, useEffect, createContext } from "react";
import Client from "shopify-buy";

const ShopContext = createContext(null);

const client = Client.buildClient({
  domain: "jsmodularcornett.myshopify.com",
  storefrontAccessToken: "65a4a68d9de0e3fa24ef1e82fae82dec",
});

const ShopProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [basket, setBasket] = useState({});
  const [cartOpen, setCartOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [collections, setCollections] = useState([]);

  // useEffect(() => {
  //     createCheckout();
  // },[])%

  const createCheckout = async () => {
    const checkout = await client.checkout.create();
    setBasket(checkout);

    console.log(checkout);

    // localStorage.setItem('checkout', checkout.id)
  };

  const fetchCheckout = (id) => {
    client.checkout.fetch(id).then((checkout) => {
      setBasket(checkout);
    });
  };

  const fetchProducts = async () => {
    const products = await client.product.fetchAll();
    setProducts(products);
    console.log(products);
  };

  const fetchProductWithId = async (id) => {
    const product = await client.product.fetch(id);
    setProduct(product);
  };

  const closeCart = () => setCartOpen(false);

  const openCart = () => setCartOpen(true);

  const addItemToCart = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];
    console.log(lineItemsToAdd);
    const checkout = await client.checkout.addLineItems(
      basket.id,
      lineItemsToAdd
    );
    setBasket(checkout);
    setNumberOfItems(numberOfItems + 1);
    console.log(numberOfItems);
    console.log(checkout);
  };

  const removeLineItemInCart = async (lineItemId) => {
    const checkout = await client.checkout
      .removeLineItems(basket.id, lineItemId)
      .then((res) => {
        setBasket(res);
        setNumberOfItems(numberOfItems - 1);
      });
  };
  const updateQuantity = async (lineItemId, quantity) => {
    const checkoutId = basket.id;
    const lineItemsToUpdate = [{ id: lineItemId, quantity: +quantity }];

    return await client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        setBasket(res);
        console.log(lineItemsToUpdate);
      });
  };

  const decrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity - 1;
    updateQuantity(itemId, updatedQuantity);
    // console.log(lineItemId.quantity)
    // this.setState({numberOfItems: this.state.numberOfItems - 1})
    setNumberOfItems(numberOfItems - 1);
    // console.log(this.state.numberOfItems)
  };

  const incrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity + 1;
    updateQuantity(itemId, updatedQuantity);
    setNumberOfItems(numberOfItems + 1);
  };
  return (
    <ShopContext.Provider
      value={{
        products,
        product,
        fetchProducts,
        fetchProductWithId,
        cartOpen,
        openCart,
        closeCart,
        addItemToCart,
        basket,
        removeLineItemInCart,
        incrementQuantity,
        decrementQuantity,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};
const MainConsumer = ShopContext.Consumer;
export { MainConsumer, ShopContext };

export default ShopProvider;

import React, { useState, useEffect } from "react";
import marked from "marked";
import { getTeachingPage } from "../../contentful";
import "./TeachingPageStyles.scss";
import PageTitle from "../PageTitle/PageTitle";

const Teaching = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getTeachingPage().then((data) => setContent(data[0].fields));
  }, []);
  return (
    <>
      <PageTitle title='Teaching' />
      <div className='page-content teaching-page'>
        {content.mainImage && (
          <div className='teaching-page__image'>
            {" "}
            <img src={content.mainImage.fields.file.url} />
          </div>
        )}
        <div className='teaching-page__text'>
          {content.text && (
            <section
              dangerouslySetInnerHTML={{ __html: marked(content.text) }}
            />
          )}

          {content.quote && (
            <section
              className='teaching-page__quote'
              dangerouslySetInnerHTML={{ __html: marked(content.quote) }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Teaching;

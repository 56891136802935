import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import "./Toolbar.scss";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

const Toolbar = ({ setShowModal, drawerClickHandler, menuOpen }) => {
  const [bgc, setBgc] = useState("transparent");
  const location = useLocation();
  const onScroll = () => {
    if (window.scrollY > 0) {
      setBgc("rgba(50, 49, 48, 0.8)");
    } else {
      setBgc("transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <header style={{ backgroundColor: bgc }} className='toolbar'>
      <nav className='toolbar__navigation'>
        <div className='toolbar__toggle-button'>
          {location.pathname !== "/breathwork-course" && (
            <DrawerToggleButton menuOpen={menuOpen} click={drawerClickHandler} />
          )}
        </div>
        <div className='toolbar_navigation-items'>
          <button onClick={() => setShowModal(true)} className='special-btn toolbar__contact'>
            Contact Me
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Toolbar;

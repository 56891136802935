import React, { useState, useEffect } from "react";
import AudioPlayer from "../../components/SingleAudioPlayer/SingleAudioPlayer";
import { getAudio } from "../../contentful";
import marked from "marked";
import "./AudioPageStyles.scss";
import PageTitle from "../../components/PageTitle/PageTitle";

const AudioPage = () => {
  const [tracks, setTracks] = useState([]);
  const [activeTrack, setActiveTrack] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getAudio().then((data) => setTracks(data));
  }, []);

  const groupedTracks = () => {
    const projects = [...new Set(tracks.map((t) => t.fields.projectTitle))];

    return projects.reduce((acc, p) => {
      const description = tracks.find((t) => t.fields.projectTitle === p).fields
        .projectDescription;

      const image = tracks.find((t) => t.fields.projectTitle === p).fields
        ?.projectImage?.fields.file.url;
      const proj = {
        title: p,
        description,
        tracks: [...tracks.filter((t) => t.fields.projectTitle === p)],
        image,
      };
      acc.push(proj);

      return acc;
    }, []);
  };

  return (
    <>
      <div className='page-content audio-page '>
        <PageTitle title='Listen' />
        <div className='audio-page__content'>
          {groupedTracks()?.map((t) => (
            <div className='audio-page__group'>
              <h4>{t.title}</h4>
              {t.image && <img src={t.image} className='audio-item__image' />}
              {t.description && (
                <div
                  dangerouslySetInnerHTML={{ __html: marked(t.description) }}
                />
              )}
              {t.tracks.map((track) => (
                <div className='audio-item'>
                  <div className='audio-item__player'>
                    <AudioPlayer
                      excerpt={t.title === "Oh Joy"}
                      activeTrack={activeTrack}
                      setActiveTrack={setActiveTrack}
                      src={track.fields.file.fields.file.url}
                    />
                  </div>
                  <div>
                    <h5>{track.fields.title}</h5>
                    {track.fields.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marked(track.fields.description),
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AudioPage;

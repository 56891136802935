import React from "react";
import { Modal } from "react-bootstrap";
import { FaPhoneAlt, FaEnvelopeOpen } from "react-icons/fa";
import "./ContactBarStyles.scss";

const ContactBar = ({ showModal, setShowModal }) => {
  return (
    <div className='contact-bar'>
      <div className='m-2 text-center'>
        <button onClick={() => setShowModal(true)} className='special-btn'>
          Contact Me
        </button>
      </div>

      <Modal show={showModal}>
        <div className='contact-modal'>
          <h3>Contact Me</h3>

          <div style={{ marginBottom: 40 }}>
            <FaPhoneAlt /> +44 (0)7811 956 344
          </div>
          <div style={{ marginBottom: 40 }}>
            <a href='mailto:orenmarshall@hotmail.com'>
              {" "}
              <FaEnvelopeOpen /> orenmarshall@hotmail.com
            </a>
          </div>
          <button className='button' onClick={() => setShowModal(false)}>
            close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ContactBar;

import React, { useEffect, useState } from "react";
import { getJazzForToddlersPage, getOhJoy } from "../../contentful";
import marked from "marked";
import "./JazzForToddlersStyles.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import AudioPlayer from "../../components/SingleAudioPlayer/SingleAudioPlayer";
import GalleryDisplay from "../../components/Gallery/Gallery";

const JazzForToddlers = () => {
  const [content, setContent] = useState({});
  const [activeTrack, setActiveTrack] = useState(null);
  const [joy, setJoy] = useState({});
  useEffect(() => {
    getJazzForToddlersPage().then((data) => setContent(data[0].fields));
    getOhJoy().then((data) => setJoy(data[0].fields));
  }, []);
  if (!content.text || !joy.coverImage) return null;
  return (
    <>
      <div className='page-content'>
        <div className='jazz'>
          <div className='jazz__text__container'>
            <PageTitle title='Jazz For Toddlers' />

            <div
              className='jazz__text'
              dangerouslySetInnerHTML={{ __html: marked(content.text) }}
            />
          </div>
          <div className='jazz__image'>
            <img src={content.image.fields.file.url} />
          </div>
        </div>
        <div className='joy'>
          <div className='joy__image'>
            <img src={joy.coverImage.fields.file.url} />
          </div>
          <div className='joy__text'>
            <a
              className='button-link more-button'
              href='https://orenmarshall1.bandcamp.com/album/oren-marshalls-jazz-for-toddlers-presents-oh-joy-songs-for-children-of-all-ages-2'
              target='_blank'
            >
              Buy the Album! >>>
            </a>
            <ul className='joy__tracks'>
              {joy?.track.map((t, i) => (
                <li key={i} className='joy__tracks__track'>
                  <AudioPlayer
                    excerpt
                    activeTrack={activeTrack}
                    setActiveTrack={setActiveTrack}
                    small
                    src={t.fields.file.url}
                  />
                  <span>{t.fields.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default JazzForToddlers;

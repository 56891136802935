import React, { useState, useEffect } from "react";
import { getBlog } from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getBlog().then((data) => setPosts(data));
  });

  return (
    <div>
      {posts.map((post) => {
        const text = marked(post.fields.mainText);

        return (
          <div>
            <Row>
              <Col>
                <div style={{ padding: 25 }}>
                  <h3>{post.fields.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              </Col>
              <Col>
                <img src={post.fields.mainImage.fields.file.url} style={{ width: "100%" }} alt='' />
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Blog;

import React, { useState, useEffect } from "react";
import { getHomepageText } from "../../contentful";
import marked from "marked";
import { Link } from "react-router-dom";
import HomeProjectCard from "../../components/HomeProjectCard";
import Slider from "../../components/Slider";
import ScrollAnimation from "react-animate-on-scroll";
import solo from "../../assets/imgs/SoloWork.jpg";
import trio from "../../assets/imgs/trio.jpg";
import jazz from "../../assets/imgs/JazzForToddlers.jpg";
import teaching from "../../assets/imgs/portrait.jpg";
import TrioHero from "../../components/TrioHero/TrioHero";
import Hero from "../../components/Hero/Hero";
import "./HomeStyles.scss";
const Home = () => {
  const [projects, setProjects] = useState([]);
  const [video, setVideo] = useState([]);
  const [content, setContent] = useState({});

  useEffect(() => {
    getHomepageText().then((data) => setContent(data[0].fields));
  }, []);

  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      {content.mainImage && <Hero image={content?.mainImage} />}
      {content.text && <div className='homepage__text' dangerouslySetInnerHTML={{ __html: marked(content.text) }} />}
      <div className='content homepage-features'>
        <ScrollAnimation animateIn='slideInLeft'>
          <Link to='jazz_for_toddlers'>
            <div className='slider right-slider' style={{ backgroundImage: `url(${jazz})` }}>
              <span className='slider-text'>Jazz for Toddlers</span>
            </div>{" "}
          </Link>
        </ScrollAnimation>

        <ScrollAnimation animateIn='slideInRight'>
          <Link to='/pertubando'>
            <div className='slider left-slider' style={{ backgroundImage: `url(${trio})` }}>
              <span className='slider-text'>Pertubando</span>
            </div>{" "}
          </Link>
        </ScrollAnimation>

        <ScrollAnimation animateIn='slideInLeft'>
          <Link to='/teaching'>
            <div className='slider left-slider' style={{ backgroundImage: `url(${teaching})` }}>
              <span className='slider-text'>Teaching</span>
            </div>{" "}
          </Link>
        </ScrollAnimation>

        <ScrollAnimation animateIn='slideInRight'>
          <Link to='/breathwork'>
            <div className='slider right-slider' style={{ backgroundImage: `url(${solo})` }}>
              <span className='slider-text'>Breathwork</span>
            </div>{" "}
          </Link>
        </ScrollAnimation>
      </div>
      <TrioHero />
    </div>
  );
};

export default Home;

import React, { useState, useRef, useEffect } from "react";
import { getAudio } from "../../contentful";
import AudioControls from "./AudioControls";
import "./AudioPlayerStyles.scss";
import { FaHeart } from "react-icons/fa";
import { ReactComponent as Pause } from "../../assets/pause.svg";

const AudioPlayer = ({ selectedTracks, rtb }) => {
  const [tracks, setTracks] = useState([]);
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [miniPlayer, setMiniPlayer] = useState(true);
  const containerRef = useRef();
  const [height, setHeight] = useState(30);
  const [likedTracks, setLikedTracks] = useState([]);

  useEffect(() => {
    setHeight(miniPlayer ? 20 : containerRef.current.scrollHeight);
  }, [miniPlayer]);

  useEffect(() => {
    !selectedTracks
      ? getAudio().then((data) => {
          setTracks(
            data.filter((track) => track.fields.projectTitle !== "Oh Joy")
          );
        })
      : setTracks(selectedTracks);
  }, [selectedTracks]);

  let title, artist, audioSrc, image;
  if (tracks.length) {
    title = tracks[trackIndex].fields.file.fields.title;
    artist = tracks[trackIndex].fields.file.fields.description;
    audioSrc = tracks[trackIndex].fields.file.fields.file.url;
    // image = tracks[trackIndex].fields?.image?.fields?.file?.url
    //   ? tracks[trackIndex].fields?.image?.fields?.file?.url
    //   : getDefaultImage();
    image = "";
  }

  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };

  useEffect(() => {
    const tracks = window.localStorage.getItem("likedTracks");
    tracks && setLikedTracks(JSON.parse(tracks));
  }, []);

  useEffect(() => {
    console.log("running");

    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  // Handle setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();
    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);
    if (isReady.current && isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [trackIndex, isPlaying]);

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  // close on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setMiniPlayer(true);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [containerRef]);

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #eee), color-stop(${currentPercentage}, 'black'))
`;

  const trackClick = (index) => {
    setTrackIndex(index);
    if (!isPlaying) setIsPlaying(true);
  };

  const trackLike = (title) => {
    if (!likedTracks.includes(title)) {
      setLikedTracks([...likedTracks, title]);
      window.localStorage.setItem(
        "likedTracks",
        JSON.stringify([...likedTracks, title])
      );
    } else {
      const tracks = likedTracks.filter((t) => t !== title);
      console.log(tracks);
      console.log(title);
      setLikedTracks(tracks);
      window.localStorage.setItem("likedTracks", JSON.stringify(tracks));
    }
  };

  return (
    <div
      ref={containerRef}
      className={miniPlayer ? "audio-player--mini" : "audio-player"}
      style={{ height }}
    >
      <div className='track-info'>
        {isPlaying && (
          <div className='audio-active'>
            <div className='audio-active__bar' />
            <div
              className='audio-active__bar'
              style={{ animationDelay: "300ms" }}
            />
            <div
              className='audio-active__bar'
              style={{ animationDelay: "600ms" }}
            />
          </div>
        )}
        <p>
          <small
            className='audio-player--toggle'
            onClick={() => setMiniPlayer(!miniPlayer)}
          >
            {!miniPlayer ? "hide audio player" : "show audio player"}
          </small>
        </p>
        {miniPlayer && isPlaying && (
          <button
            type='button'
            className='mini-pause'
            onClick={() => setIsPlaying(false)}
            aria-label='Pause'
          >
            <Pause />
          </button>
        )}

        {/* <div className='audio-artwork-wrapper'>
          {image && (
            <img
              className='artwork'
              src={image}
              alt={`track artwork for ${title} by ${artist}`}
            />
          )}
        </div> */}
        {/* <h5 className='title'>{title}</h5>
        {artist && <h5 className='artist'>{artist}</h5>} */}
        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
        <input
          type='range'
          value={trackProgress}
          step='1'
          min='0'
          max={duration ? duration : `${duration}`}
          className='progress'
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
      </div>

      <div className='tracklist'>
        {tracks.map((track, index) => (
          <div
            key={track.fields.file.fields.title}
            className={trackIndex !== index ? "track" : "track--active"}
          >
            <span onClick={() => trackClick(index)}>
              {track.fields.file.fields.title}
            </span>
            <FaHeart
              onClick={() => trackLike(track.fields.file.fields.title)}
              className={
                !likedTracks.includes(track.fields.file.fields.title)
                  ? "fav-track"
                  : "fav-track liked"
              }
            />
          </div>
        ))}
      </div>
      <div className='disclaimer'>
        liked tracks are stored in your browser and will be remembered for your
        next visit.
      </div>
    </div>
  );
};

export default AudioPlayer;

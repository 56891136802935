const client = require("contentful").createClient({
  space: "e512wc2iror7",
  accessToken: "5QPDSzjxiFXfqsvc1M-kfyG_-amgHan3qo5ytObclSA",
});

const getBiog = () =>
  client
    .getEntries({ content_type: "biography" })
    .then((response) => response.items);
const getBreathworkPage = () =>
  client
    .getEntries({ content_type: "breathworkPage" })
    .then((response) => response.items);
const getRecordings = () =>
  client
    .getEntries({ content_type: "recording" })
    .then((response) => response.items);
const getRecordingsList = () =>
  client
    .getEntries({ content_type: "discographyList" })
    .then((response) => response.items);
const getShopText = () =>
  client
    .getEntries({ content_type: "shopText" })
    .then((response) => response.items);

const getHomepageText = () =>
  client
    .getEntries({ content_type: "homepage" })
    .then((response) => response.items);

const getBlog = () =>
  client
    .getEntries({ content_type: "blog" })
    .then((response) => response.items);
const getSingleBlog = (slug) =>
  client
    .getEntries({ content_type: "blog", "sys.id": slug })
    .then((response) => response.items);
const getTrioInfo = () =>
  client
    .getEntries({ content_type: "trio" })
    .then((response) => response.items);

const getAudio = () =>
  client.getEntries({ content_type: "mp3" }).then((response) => response.items);

const getTeachingPage = () =>
  client
    .getEntries({ content_type: "teaching" })
    .then((response) => response.items);
const getJazzForToddlersPage = () =>
  client
    .getEntries({ content_type: "jazzForToddlers" })
    .then((response) => response.items);

    const getOhJoy = () =>
    client
      .getEntries({ content_type: "ohJoy" })
      .then((response) => response.items);

const getGalleryImages = () =>
  client
    .getEntries({ content_type: "galleryImage" })
    .then((response) => response.items);

export {
  getTeachingPage,
  getAudio,
  getHomepageText,
  getTrioInfo,
  getShopText,
  getBiog,
  getRecordings,
  getRecordingsList,
  getBlog,
  getSingleBlog,
  getGalleryImages,
  getBreathworkPage,
  getJazzForToddlersPage,
  getOhJoy
};
